<template>
    <div id="content" class="user-management">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="validateLocation" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'" :customClass="{ disabled: !id_new, 'btn-action': id_new }"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showConfirmDeleteLocationModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container">
            <div class="form">
                <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                <div class="label-container">
                    <div class="left-container" :class="{ disabled: !id_new }">
                        <!-- <h2 class="label-form">{{ $t('user.user_name') }}</h2>-->
                        <!-- <p v-if="emptyAnswer.name" class="error-form" v-html="$t('sites.form.error.name')"></p> -->
                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.name" :gState="emptyAnswer.name ? 'error' : ''" :gOptions="{ mandatory: true, disabled: !id_new }" :gLabel="$t('sites.form.name_local')" :gMessage="{ error: $t('library.form.error_name') }" gSize="m"></Input>
                            <!-- <input type="text" v-model="locationRender.name" class="input w85" autocomplete="off" :placeholder="$t('sites.form.name_placeholder')" name="name" :class="{ disabled: !id_new }" /> -->
                        </div>
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new }">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="statusLocation ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="statusLocation" disabled="true" />
                            </div>
                        </div>

                        <p class="description" v-html="$t('sites.form.inactive_desc')"></p>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <!-- <h2 class="label-form">{{ $t('user.id') }}</h2>
                    <p class="description" v-html="$t('sites.form.id_desc')"></p> -->
                        <p v-if="!id_new" class="error-form" v-html="$t('sites.form.error.id_used')"></p>
                        <!-- <p v-if="emptyAnswer.id" class="error-form" v-html="$t('sites.form.error.id_empty')"></p> -->
                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.identification" :customClass="{ 'input-clear': id && !clickEditId }" @blur="searchID()" :gState="idState()" :gOptions="{ mandatory: true, disabled: id && !clickEditId }" :gLabel="$t('sites.form.number_local')" :gMessage="$t('sites.form.id_desc')" gSize="m"></Input>
                            <Button v-if="id && !clickEditId" bType="edit" :bLabel="false" :bCallback="editIdLocal" customClass="btn-secondary center-btn btn-clear btn-input"></Button>
                            <!-- <input type="text" v-model="locationRender.identification" class="input w85" autocomplete="off" name="identification" @blur="searchID()" /> -->
                        </div>
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new }">
                        <!-- <h2 class="label-form">{{ $t('sites.form.territory') }}</h2>
                    <p class="description" v-html="$t('sites.form.territory_desc')"></p> -->
                        <div class="user-answer">
                            <Input :gOptions="{ returnObject: true, multiple: true, itemText: 'name', itemValue: 'id' }" :gLabel="$t('sites.form.territory')" gType="autocomplete" :autocompleteValue="locationRender.groups" v-model="locationRender.groups" :gItems="Object.values(optionsGroups)" gSize="m"></Input>
                            <!-- <v-autocomplete hide-details class="select" v-model="locationRender.groups" :items="Object.values(optionsGroups)" item-text="name" item-value="id" multiple :placeholder="$t('sites.form.territory_placeholder')" dense :no-data-text="$t('supervise.filter.no_results')" return-object>
                            <template v-slot:selection="{ item, index }">
                                {{ index == 0 ? '' : '' }}
                            </template>
                        </v-autocomplete> -->
                        </div>
                        <!-- <div class="user-answer ">
                        <Tags :tags="locationRender.groups" :limit="false" :nameTag="'name'" value="id" :removable="true"></Tags>
                    </div> -->
                    </div>
                </div>
                <div class="label-container" :class="{ disabled: !id_new }">
                    <div class="left-container">
                        <!-- <h1 class="form-title">{{ $t('sites.form.contact') }}</h1> -->
                        <!-- <h2 class="label-form">{{ $t('sites.form.email') }}</h2> -->
                        <!-- <p v-if="emptyAnswer.email" class="error-form" v-html="$t('sites.form.error.email')"></p> -->
                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.email" @input="validateResend()" :gState="emptyAnswer.email || emptyAnswer.emailTaken ? 'error' : emptyAnswer.emailCheck ? 'error2' : ''" :gOptions="{ mandatory: true }" :gLabel="$t('sites.form.email_local')" :gMessage="{ default: '', error: emptyAnswer.email ? $t('sites.form.email_notify_info') : $t('signup.form.error.email_taken'), error2: $t('user.form.error.not_email') }" gSize="m"></Input>

                            <!-- <input type="text" v-model="locationRender.email" class="input w85" autocomplete="off" name="email" :placeholder="$t('sites.form.email_placeholder')" :class="{ disabled: !id_new }" /> -->
                        </div>
                    </div>
                    <div class="right-container">
                        <!-- <h2 class="label-form">{{ $t('sites.form.tel') }}</h2> -->
                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.phone" :gLabel="$t('sites.form.tel')" gSize="m"></Input>

                            <!-- <input type="text" v-model="locationRender.phone" class="input w85" autocomplete="off" name="tel" :placeholder="$t('sites.form.tel_placeholder')" :class="{ disabled: !id_new }" /> -->
                        </div>
                    </div>
                </div>
                <!-- ACCESO A ANDY APP -->
                <div class="label-container roles" :class="{ disabled: !id_new }">
                    <h1 class="form-title">
                        {{ $t('user.form.alex_app_access') }}
                    </h1>
                    <p class="description">
                        {{ $t('user.form.alex_app_access_desc') }}
                    </p>
                    <div class="label-content">
                        <div class="left-container">
                            <!-- SI ESTA CREANDO/AÑADIENDO -->
                            <template v-if="!id">
                                <!-- <div class="info-box access">
                                {{ $t('sites.form.access_app_info') }}
                            </div> -->
                                <div class="user-answer">
                                    <Input gType="text" gState="disabled" :gHelp="$t('sites.form.id_local_help')" :value="idPrefix != null && idPrefix != 'null' ? idPrefix + '.' + locationRender.identification : locationRender.identification" :gOptions="{ disabled: true }" :gLabel="$t('sites.form.id_local')" :gMessage="{ error: $t('library.form.error_name') }" gSize="m"></Input>
                                </div>
                                <div class="user-answer">
                                    <Input gType="number" :value="locationRender.password" :gHelp="$t('sites.form.access_key_help')" :gState="emptyAnswer.pass ? 'error' : ''" v-model="locationRender.password" :gOptions="{ mandatory: true }" :gLabel="$t('sites.form.access_key')" :gMessage="$t('sites.form.password_placeholder')" gSize="m"></Input>
                                </div>
                                <Button bType="refresh" :bCallback="generatePassRandom" bLabel="sites.form.generate_random_key" customClass="btn-secondary btn-clear"></Button>
                            </template>
                            <template v-else>
                                <div class="info-box access" v-if="isEditId">
                                    {{ $t('sites.form.access_app_info2') }}
                                </div>
                                <div class="info-box warning" v-if="isResetAccess">
                                    {{ $t('sites.form.access_app_warning') }}
                                </div>
                                <div class="user-answer">
                                    <Input gType="text" gState="disabled" :gHelp="$t('sites.form.id_local_help')" :value="idPrefix != null && idPrefix != 'null' ? idPrefix + '.' + locationRender.identification : locationRender.identification" :gOptions="{ disabled: true }" :gLabel="$t('sites.form.id_local')" :gMessage="{ error: $t('library.form.error_name') }" gSize="m"></Input>
                                </div>
                            </template>
                        </div>
                        <div class="right-container" v-if="id" :class="{ disabled: !id_new }">
                            <!-- SI ESTA EDITANDO -->
                            <template v-if="!isResetAccess && !isEditId">
                                <h2 class="label-form">{{ $t('sites.form.access_key') }}</h2>
                                <div class="user-answer access">
                                    <Button bType="reset" :bCallback="resetAccess" bLabel="sites.form.restore_key" :customClass="['btn-secondary btn-password', 'btn-clear', { disabled: !id_new }]"></Button>
                                    <Button v-if="locationRender.encrypted_pwd != '' && !changedEmail" bType="reset" :bCallback="resendAccess" bLabel="sites.form.resend_access" :customClass="['btn-secondary btn-email', 'btn-clear', { disabled: !id_new }]"></Button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="user-answer">
                                    <Input gType="number" :gHelp="$t('sites.form.access_key_help')" :customClass="{ 'input-clear': isResetAccess, 'error-field': emptyAnswer.pass }" :value="locationRender.password" :gState="emptyAnswer.pass ? 'error' : ''" v-model="locationRender.password" :gOptions="{ mandatory: true }" :gLabel="$t('sites.form.access_key')" :gMessage="$t('sites.form.password_placeholder')" gSize="m"></Input>
                                    <Button v-if="isResetAccess" bType="cancel" :bCallback="cancelReset" customClass="btn-second center-btn btn-clear btn-reset"></Button>
                                </div>
                                <Button bType="refresh" :bCallback="generatePassRandom" bLabel="sites.form.generate_random_key" customClass="btn-secondary btn-clear"></Button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="label-container spaced" :class="{ disabled: !id_new }">
                    <div class="left-container">
                        <div class="up">
                            <h1 class="form-title">
                                {{ $t('sites.form.direction_local') }}
                            </h1>
                        </div>
                        <!-- <h1 class="form-title">{{ $t('sites.form.direction') }}</h1> -->
                        <!-- <h2 class="label-form">{{ $t('sites.form.direction') }}</h2> -->

                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.address" :gLabel="$t('sites.form.direction')" :gPlaceholder="$t('sites.form.direction_placeholder')" gSize="m"></Input>
                            <!-- <input type="text" v-model="locationRender.address" class="input w85" autocomplete="off" name="address" :placeholder="$t('sites.form.direction_placeholder')" :class="{ disabled: !id_new }" /> -->
                        </div>
                    </div>
                    <div class="right-container align-bottom">
                        <!-- <h2 class="label-form">{{ $t('sites.form.latitude') }}</h2> -->

                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.latitude" :gLabel="$t('sites.form.latitude')" :gPlaceholder="$t('sites.form.latitude_placeholder')" gSize="m"></Input>
                            <!-- <input type="text" v-model="locationRender.latitude" class="input w85" autocomplete="off" name="latitude" :placeholder="$t('sites.form.latitude_placeholder')" :class="{ disabled: !id_new }" /> -->
                        </div>
                    </div>
                </div>
                <div class="label-container" :class="{ disabled: !id_new }">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('sites.table_headers.city') }}</h2>

                        <div class="user-answer combobox">
                            <!-- <Input :gOptions="{ returnObject: false }" :gLabel="$t('sites.table_headers.city')" gType="autocomplete" v-model="locationRender.city" :gItems="Object.values(optionsCity)" gSize="m"></Input> -->
                            <v-combobox :placeholder="$t('sites.form.city_placeholder')" ref="cityComboBox" v-model="city" @change="onAutoCompleteSelectionCity" @keyup="customOnChangeHandlerCity" @paste="customOnChangeHandlerCity" item-text="name" item-value="id" :items="Object.values(optionsCity)" :return-object="false" :no-data-text="$t('supervise.filter.no_results')"></v-combobox>
                        </div>
                    </div>
                    <div class="right-container">
                        <!-- <h2 class="label-form">{{ $t('sites.form.longitude') }}</h2> -->
                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.longitude" :gLabel="$t('sites.form.longitude')" :gPlaceholder="$t('sites.form.longitude_placeholder')" gSize="m"></Input>
                            <!-- <input type="text" class="input w85" autocomplete="off" :placeholder="$t('sites.form.longitude_placeholder')" v-model="locationRender.longitude" name="email" id="email" /> -->
                        </div>
                    </div>
                </div>
                <div class="label-container" :class="{ disabled: !id_new }">
                    <div class="left-container">
                        <!-- <h2 class="label-form">{{ $t('sites.form.postal_code') }}</h2> -->
                        <div class="user-answer">
                            <Input gType="text" v-model="locationRender.cp" :gLabel="$t('sites.form.postal_code')" gSize="m"></Input>
                            <!-- <input type="number" class="input w85" autocomplete="off" v-model="locationRender.cp" name="postal_code" /> -->
                        </div>
                    </div>

                    <div class="right-container">
                        <h2 class="label-form">{{ $t('sites.table_headers.province') }}</h2>
                        <div class="user-answer combobox">
                            <!-- <Input :gOptions="{ returnObject: false }" :gLabel="$t('sites.table_headers.province')" gType="autocomplete" v-model="locationRender.province" :gItems="Object.values(optionsStates)" gSize="m"></Input> -->
                            <v-combobox :placeholder="$t('sites.form.province_placeholder')" :items="Object.values(optionsStates)" item-text="name" item-value="id" :return-object="false" :no-data-text="$t('supervise.filter.no_results')" ref="provinceComboBox" v-model="province" @change="onAutoCompleteSelection" @keyup="customOnChangeHandler" @paste="customOnChangeHandler"></v-combobox>
                        </div>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <div class="user-answer">
                            <Input :key="Object.keys(timeZones).length" :autocompleteValue="locationRender.timezone" :gOptions="{ returnObject: false, newInput: true, removable: false, referenceObject: timeZones, mandatory: false, itemText: 'utc', itemValue: 'id' }" :gLabel="$t('settings.timezone')" gType="autocomplete" v-model="locationRender.timezone" :gItems="Object.values(timeZones)" gSize="m"></Input>
                        </div>
                    </div>
                </div>
                <div class="label-container spaced" :class="{ disabled: !id_new }" v-if="customFields.length > 0">                    
                    <div class="left-container">
                        <div class="up">
                            <h1 class="form-title">
                                {{ $t('sites.form.more_information') }}
                            </h1>
                        </div>
                        </div>
                    </div>
                    <div class="custom-fields" v-if="customFields.length > 0">
                        <template v-for="(field) in customFields">
                            <div class="user-answer">
                                <Input gType="text" v-model="customFieldsSave[field.id]" :gLabel="field.public_name" :gMessage="$t('sites.form.custom_fields.message',{description:field.description, var:field.keyname})" gSize="m"></Input>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'LocationManagement',
    props: { id: undefined },
    components: { Tags },
    data() {
        return {
            passChanged: false,
            statusLocation: undefined,
            location: {
                city: '',
                cp: '',
                email: '',
                groups: '',
                identification: '',
                latitude: '',
                longitude: '',
                name: '',
                phone: '',
                password: '',
                state: '',
                status: '1',
                timezone: '',
                password_text: '',
                customFields: {}
            },
            // FORM STATES
            isResetAccess: false,
            clickEditId: false,
            isEditId: false,
            //
            // TEMPORAL COPY VALUES
            backupId: '',
            backupPassword: '',
            noEncryptedPass: false,
            //
            sendForm: true,
            id_new: true,
            sites: 'locations',
            emptyAnswer: {
                id: false,
                name: false,
                status: false,
                pass: false,
                emailTaken: false
            },
            hasPin: false,
            falsePin: '12345678',
            tempPin: '',
            moreOptions: false,
            province: null,
            city: null,
            changedEmail: false,
            copyEmail: false,
            emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            customFieldsSave: {}
        }
    },
    computed: {
        optionsLocations() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'territories', name: i18n.t('user.filters.territories') }
            ]
        },
        locationRender() {
            if (this.id) {
                var location = {
                    ...this.$store.getters['location/getList'](this.id)
                }
                if (location) {
                    this.statusLocation = location.status

                    // LOGICA BOTON PIN
                    if (location.password) {
                        this.hasPin = true
                    } else {
                        this.passChanged = true
                    }
                    if (location.status == '-2') {
                        this.statusLocation = false
                    }
                    this.province = location.state
                    this.city = location.city
                    this.copyEmail = JSON.parse(JSON.stringify(location.email))
                }
                //set all values of null to ''
                location = _.mapValues(location, (v) => (_.isNil(v) ? '' : v))
                this.location = location
            } else {
                this.statusLocation = '1'
            }
            if(!this.location.custom_fields){
                if(this.customFields){
                    this.customFieldsSave = this.customFields.reduce((acc, field) => {
                        acc[field.id] = ''
                        return acc
                    }, {})
                }
            }else{
                this.customFieldsSave = this.location.custom_fields
            }
            return this.location
        },
        customFields() {
            const customFields = this.$store.getters['login/getCustomFields']
            if(customFields){
                const filteredCustomFields = customFields.filter((field) => field.type === 'site')
    
                return filteredCustomFields
            }
            return false
        },
        idPrefix() {
            if (typeof localStorage.user !== 'undefined') {
                var user = JSON.parse(localStorage.user)
                return user.prefix
            } else {
                return ''
            }
        },
        optionsStatus() {
            return this.$store.getters['location/getFilterStatus']()
        },
        optionsStates() {
            return this.$store.getters['location/getFilterStates']()
        },
        optionsCity() {
            return this.$store.getters['location/getFilterCities']()
        },
        optionsGroups() {
            return this.$store.getters['location/getFilterGroups']()
        },

        user() {
            var userLoggedId = this.$store.getters['login/getLocalEmployee'].employee_id
            var loggedUser = this.$store.getters['employee/getAccountEmployee'](userLoggedId)
            if (loggedUser) {
                return loggedUser
            }
            return false
        },

        timeZones() {
            return this.$store.getters['account/getTimeZones']
        },

        licenses() {
            return this.$store.getters['location/getLicenses']
        }
    },
    methods: {
        // emailCheck() {
        //     this.locationRender && (this.locationRender.email == '' || this.locationRender.email == null) ? (this.emptyAnswer.email = true) : this.emailReg.test(this.locationRender.email) ? (this.emptyAnswer.email = false) : (this.emptyAnswer.email = true)
        //     this.emptyAnswer = this.emptyAnswer
        // },

        idState() {
            if (this.id && !this.clickEditId) {
                return 'disabled'
            } else if (this.emptyAnswer.id || !this.id_new) {
                return 'error'
            }
            return ''
        },

        editIdLocal() {
            this.clickEditId = true
            // this.isEditId = true
            this.backupId = this.location.identification
            this.backupPassword = this.location.password
            // this.location.password = ''
        },
        cancelEditId() {
            this.isEditId = false
            this.location.password = this.backupPassword
            //     this.backupId = ''
            //     this.backupPassword = ''
        },

        resetAccess() {
            this.isResetAccess = true
            this.backupPassword = this.location.password
            this.location.password = ''
        },
        resendAccess() {
            var self = this
            this.$store.dispatch('location/getPwdLocation', this.id).then((res) => {
                if (res.status) {
                    self.popupSave('snackbar.add_success', self.location, res.data)
                }
            })
        },
        validateResend() {
            if (this.location.email != this.copyEmail) {
                this.changedEmail = true
            } else {
                this.changedEmail = false
            }
        },
        cancelReset() {
            this.isResetAccess = false
            this.location.password = this.backupPassword
            // this.backupPassword = ''
        },

        onChangeHandler() {
            // console.log('So this is your favorite number: ' + this.locationRender.state)
        },
        onAutoCompleteSelection() {
            this.locationRender.state = this.province
            this.onChangeHandler()
        },
        customOnChangeHandler() {
            let vm = this
            setTimeout(function () {
                if (vm.$refs.provinceComboBox) {
                    vm.locationRender.state = vm.$refs.provinceComboBox.internalSearch
                    vm.onChangeHandler()
                }
            })
        },
        onChangeHandlerCity() {
            // console.log('So this is your favorite number: ' + this.locationRender.city)
        },
        onAutoCompleteSelectionCity() {
            this.locationRender.city = this.city
            this.onChangeHandler()
        },
        customOnChangeHandlerCity() {
            let vm = this
            setTimeout(function () {
                if (vm.$refs.cityComboBox) {
                    vm.locationRender.city = vm.$refs.cityComboBox.internalSearch
                    vm.onChangeHandlerCity()
                }
            })
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },

        setPin() {
            this.tempPin = this.locationRender.password
            this.locationRender.password = ''
            this.hasPin = false
            this.passChanged = true
        },
        popupSetPin() {
            var self = this

            self.$popup.confirm({
                message: self.$t('sites.form.popup_reset_pass'),
                textSave: self.$t('sites.form.popup_reset_pass_confirm'),
                callSave: function () {
                    self.setPin()
                }
            })
        },

        generatePassRandom() {
            var length = 8
            // var charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            var charset = '0123456789'
            var val = ''
            for (var i = 0, n = charset.length; i < length; ++i) {
                val += charset.charAt(Math.floor(Math.random() * n))
            }
            // TURN PASSWORD VISIBLE ON INPUT
            // document.getElementsByClassName('input password')[0].className += ' show'
            // document.getElementsByClassName('visibility')[0].className += ' visible'
            this.locationRender.password = val
        },

        popupStatus() {
            // TODO
            var selfThis = this
            if (selfThis.statusLocation == '1' || selfThis.statusLocation == true) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('sites.form.popup_location_text'),
                    textSave: selfThis.$t('sites.form.popup_location_save'),
                    callSave: function () {
                        selfThis.statusLocation = false
                    },
                    callCancel: function () {
                        selfThis.statusLocation = true
                    }
                })
            } else {
                selfThis.statusLocation = true
            }
        },

        load() {
            if (this.$route.name == 'Sites') {
                if (this.id !== false) {
                    this.$router.push({ name: 'EditSite', params: { id: this.id } })
                } else {
                    this.$router.push({ name: 'AddSite' })
                }
            }
            const self = this
            this.$overlay.show()
            this.$store.dispatch('account/loadTimeZones').then(() => {
                self.$overlay.hide()
            })
        },

        searchID(mode = 'default') {
            // DELETE SPACES
            this.location.identification = this.location.identification.split(' ').join('')
            var self = this

            // DEVELOPMENT THAT DETECTS SAME ID RESETS THE PASSWORD
            if (this.location.identification == this.backupId) {
                this.cancelEditId()
                //     self.id_new = true
            }

            // DETECTS EDIT ID
            if (this.clickEditId && this.location.identification != this.backupId && mode != 'save') {
                this.isEditId = true
                this.backupPassword = this.location.password
                this.location.password = ''
            }
            var params = {
                location_id: this.locationRender.id,
                identification: this.locationRender.identification
            }
            if (!this.locationRender.identification == '') {
                this.$store.dispatch('location/identificationUsed', params).then(function (response) {
                    self.id_new = response
                })
            }
            // }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },

        async validateEmail() {
            var self = this
            var resp = await this.$store.dispatch('location/validateEmailLocation', { email: this.locationRender.email, id: this.locationRender.id ? this.locationRender.id : null })
            if (!resp.status) {
                self.$overlay.hide()
                self.sendForm = false
                self.emptyAnswer.emailTaken = true
                return false
            } else {
                return true
            }
        },

        validateLocation() {
            var self = this
            self.$overlay.loading()
            this.searchID('save')
            this.sendForm = true
            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
            if (this.locationRender.identification === '') {
                self.$overlay.hide()
                this.sendForm = false
                this.emptyAnswer.id = true
            }
            if (this.locationRender.password.length < 8) {
                self.$overlay.hide()
                this.sendForm = false
                this.emptyAnswer.pass = true
            }
            if (this.locationRender.name === '') {
                self.$overlay.hide()
                this.sendForm = false
                this.emptyAnswer.name = true
            }

            if (this.locationRender.email == '' || this.locationRender.email == null || this.locationRender.email == []) {
                self.$overlay.hide()
                this.sendForm = false
                this.emptyAnswer.email = true
            } else if (!this.emailReg.test(this.locationRender.email)) {
                this.sendForm = false
                self.$overlay.hide()
                this.emptyAnswer.emailCheck = true
            }

            if (this.locationRender.cp == '' || this.locationRender.cp == null || this.locationRender.cp == []) {
                this.locationRender.cp = ''
            }
            this.locationRender.status = this.statusLocation
            if (this.locationRender.status == true) {
                this.locationRender.status = '1'
            } else if (this.locationRender.status == false) {
                this.locationRender.status = '-2'
            }
            if (this.locationRender.latitude == '' || this.locationRender.latitude == null || this.locationRender.latitude == []) {
                this.locationRender.latitude = ''
            }
            if (this.locationRender.longitude == '' || this.locationRender.longitude == null || this.locationRender.longitude == []) {
                this.locationRender.longitude = ''
            }
            if (this.locationRender.phone == '' || this.locationRender.phone == null || this.locationRender.phone == []) {
                this.locationRender.phone = ''
            }

            var validate = this.validateEmail()
            var self = this
            validate.then(function (response) {
                if (self.sendForm && response) {
                    if (self.idPrefix && self.idPrefix != 'null' && self.idPrefix != null) {
                        self.locationRender.username = self.idPrefix + '.' + self.locationRender.identification
                    } else {
                        self.locationRender.username = self.locationRender.identification
                    }
                    if (self.locationRender.city == '' || self.locationRender.city == null || self.locationRender.city == []) {
                        self.locationRender.city == ''
                    }
                    if (self.locationRender.state == '' || self.locationRender.state == null || self.locationRender.state == []) {
                        self.locationRender.state == ''
                    }
                    var location = JSON.parse(JSON.stringify(self.locationRender))
                    if (location.groups.length > 0) {
                        var group = []
                        for (var i in location.groups) {
                            var idx = location.groups[i]
                            if (idx.id) {
                                group.push(idx.id)
                            }
                        }
                        location.groups = group.join()
                    }
                    if (location.groups == '' || location.groups == null || location.groups == []) {
                        location.groups == ''
                    }


                    var olocationRender = JSON.parse(JSON.stringify(location))
                    if (self.$route.params.id) {
                        if (self.passChanged || self.isResetAccess || self.isEditId) {
                            self.noEncryptedPass = olocationRender.password
                            olocationRender.password_text = olocationRender.password
                            olocationRender.password = sha256(olocationRender.password)
                        }
                        olocationRender.custom_fields = JSON.stringify(self.customFieldsSave)
                        self.$store.dispatch('location/editLocation', olocationRender).then(function (response) {
                            if (response.status) {
                                self.popupSave('snackbar.edit_success', response.data, false)
                            } else {
                                self.$popup.confirm({
                                    title: self.$t('sites.form.popup_no license_title'),
                                    message: self.$t('sites.form.popup_no license_text'),
                                    textSave: self.$t('sites.add_license'),
                                    textCancel: self.$t('user.form.in_other_moment'),
                                    callSave: () => {
                                        let url = self.$store.getters['login/getTrialLink']
                                        window.open(url, '_blank')
                                    }
                                })
                                self.$overlay.hide()
                            }
                        })
                    } else {
                        self.noEncryptedPass = olocationRender.password
                        olocationRender.password_text = olocationRender.password
                        olocationRender.password = sha256(olocationRender.password)
                        olocationRender.custom_fields = JSON.stringify(self.customFieldsSave)
                        self.$store.dispatch('location/addLocation', olocationRender).then(function (response) {
                            if (response.status) {
                                self.popupSave('snackbar.add_success', response.data, false)
                            } else {
                                self.$popup.confirm({
                                    title: self.$t('sites.form.popup_no license_title'),
                                    message: self.$t('sites.form.popup_no license_text'),
                                    textSave: self.$t('sites.add_license'),
                                    textCancel: self.$t('user.form.in_other_moment'),
                                    callSave: () => {
                                        let url = self.$store.getters['login/getTrialLink']
                                        window.open(url, '_blank')
                                    }
                                })
                                self.$overlay.hide()
                            }
                        })
                    }
                    self.$store.commit('employee/setHasLoadFilters', true)
                } else {
                    scrollError()
                }
            })
        },

        popupSave(snackbarMsg, data, resend) {
            var self = this
            if ((this.noEncryptedPass && (this.id == false || this.isResetAccess || this.isEditId)) || resend) {
                this.$popup.siteSave({
                    title: self.$t('sites.popup.title'),
                    message: self.$t('user.form.invitation_dashboard_descrip'),
                    textSave: self.$t('popup.access.text_save'),
                    textCancel: self.$t('user.form.in_other_moment'),
                    sendEmails: [
                        { email: this.locationRender.email, msg: 'sites.popup.send_access' },
                        { email: this.user.email, msg: 'sites.popup.send_access_copy' }
                    ],
                    alertMsg: this.locationRender.status == '-2' ? self.$t('sites.form.inactive_warning') : false,
                    locationName: this.locationRender.name,
                    locationId: this.locationRender.username,
                    locationKey: this.noEncryptedPass ? this.noEncryptedPass : resend,
                    callSave: function (popup) {
                        const areValidMails = popup.emails.every((email) => validEmail(email))
                        if (areValidMails) {
                            if (popup.emails.length > 0) {
                                var params = {
                                    location_id: data.id,
                                    new: snackbarMsg == 'snackbar.add_success' ? 1 : 0,
                                    identification: data.username,
                                    password: self.noEncryptedPass,
                                    emails: popup.emails.join(',')
                                }
                                if (resend) {
                                    self.$store.dispatch('location/resendAccessNotification', params)
                                } else {
                                    self.$store.dispatch('location/sendAccessNotification', params)
                                }
                            }
                            if (resend) {
                                self.$snackbar.open({
                                    message: self.$t('sites.form.resend_successfully'),
                                    customClass: 'success',
                                    duration: 5000
                                })
                            } else {
                                self.$snackbar.open({
                                    message: self.$t(snackbarMsg) + ' <b>' + self.locationRender.name + '</b>',
                                    customClass: 'success',
                                    duration: 5000
                                })
                            }
                            self.$emit('back')
                        }
                    },
                    callCancel: function () {
                        if (!resend) {
                            self.$popup.close()
                            self.$emit('back')
                            self.$snackbar.open({
                                message: self.$t(snackbarMsg) + ' <b>' + self.locationRender.name + '</b>',
                                customClass: 'success',
                                duration: 5000
                            })
                        }
                    }
                })
            } else {
                self.$snackbar.open({
                    message: self.$t(snackbarMsg) + ' <b>' + self.locationRender.name + '</b>',
                    customClass: 'success',
                    duration: 5000
                })
                self.$emit('back')
            }
        },

        deleteLocation() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.locationRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('location/deleteLocation', { id: self.locationRender.id }).then(() => {
                self.$emit('back')
                self.$snackbar.open(snackbarData)
                self.$store.commit('employee/setHasLoadFilters', true)
            })
        },

        showConfirmDeleteLocationModal() {
            var self = this
            const modalMessage = self.$t('sites.form.popup_location_delete') + self.locationRender.name + "'?"

            this.$popup.delete({ message: modalMessage, callSave: this.deleteLocation, requireTyping: true })
        }
    },

    created() {
        this.load()
    },
    mounted() {
        if (!this.location.timezone) {
            const account = this.$store.getters['account/getOrganization']
            this.location.timezone = account.account.timezone
        }
    },
    watch: {}
}
</script>

<style lang="scss">
#content.user-management {
    height: 100%;

    .label-form {
        padding-bottom: unset;
    }
    .theme--light.v-input {
        padding-left: 0px;
        height: 30px;
    }
    .disabled {
        opacity: 0.55;
        pointer-events: none;
    }
    .btn-normal {
        height: auto;
    }

    .left {
        width: 45%;
    }
    .right {
        width: 55%;
    }
    .switch {
        display: flex;

        .switch-click {
            z-index: $z-bot-content;
            cursor: pointer;
            margin: 0;
            padding: 0;

            .switch-input-form {
                z-index: -1;
                margin: 0;
            }
        }
        h2 {
            width: 75px;
        }
    }
    .inline-delete {
        margin: 60px auto 30px auto;
        width: 100%;
        text-align: center;

        .text {
            @include background($size: 16px, $image: img('delete_error.svg'), $position: left center);
            @include font-size(ml);
            color: $color-error-500;
            text-align: center;
            display: inline-block;
            padding-left: 18px;
            cursor: pointer;
        }
    }
    .form-container {
        .form {
            margin-top: -30px;
            .form-title {
                margin-bottom: 8px;
            }

            .custom-fields{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
        .label-container.spaced {
            margin-top: 8px;
        }
        .label-container {
            padding-bottom: 8px;

            .label-content {
                @include display-flex();
                justify-content: space-between;

                .left-container,
                .right-container {
                    margin: unset;
                    @include background($color: $color-neutral-50);
                    padding: 10px;

                    .user-answer {
                        &.access {
                            @include display-flex();
                            @include flex-direction(column);
                            gap: 9px;
                        }
                    }
                }
            }
        }
    }
}
</style>
